
.subject-page {
    .main-wrap {
        margin-top: 20px;
        // margin-top: 140px;
        display: flex;
        justify-content: space-between;
        .left-main-list {
            width: 860px;
            box-sizing: border-box;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            padding: 0 22px;
            margin-bottom: 40px;
            ul {
                li {
                    display: flex;
                    border-bottom: 1px solid #e6e6e6;
                    div:first-child {
                        img {
                            width: 230px;
                            height: 145px;
                            margin: 20px 19px 20px 0;
                            cursor: pointer;
                        }
                    }
                    div.content {
                        flex: 1;
                        h5 {
                            width: 565px;
                            margin-top: 20px;
                            font-size: 20px;
                            line-height: 50px;
                            cursor: pointer;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        p {
                            font-size: 14px;
                            color: #666;
                            line-height: 26px;
                            margin-top: 4px;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
                li:last-child {
                    border-bottom: none;
                }
            }
        }
        .right-main {
            width: 316px;
            box-sizing: border-box;
            // border: 1px solid #f5f5f5;
            // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            .right-download-wrap {
                width: 316px;
                padding: 0 16px;
                box-sizing: border-box;
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                .com-title {
                    height: 45px;
                    line-height: 45px;
                    border-bottom: 2px solid #dedede;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    div {
                        display: flex;
                        align-items: center;

                        img {
                            width: 24px;
                            height: 24px;
                            margin-right: 11px;
                            margin-left: 3px;
                        }
                        h5 {
                            height: 45px;
                            line-height: 45px;
                            font-size: 24px;
                            color: #000000;
                            border-bottom: 3px solid #bd1a2d;
                        }
                    }

                    ul {
                        display: flex;
                        li {
                            font-size: 18px;
                            margin-right: 18px;
                            cursor: pointer;
                            line-height: 36px;
                            color: #333;
                        }
                    }
                    span {
                        font-size: 14px;
                        color: #404040;
                        cursor: pointer;
                        img {
                            width: 4px;
                            height: 8px;
                            margin-left: 10px;
                        }
                    }
                }
                ul.right-download {
                    .first-download {
                        display: flex;
                        margin-top: 14px;
                        div {
                            img {
                                width: 118px;
                                height: 74px;
                                margin-right: 9px;
                                margin-bottom: 15px;
                            }
                            h5 {
                                width: 155px;
                                font-size: 16px;
                                color: #333333;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                margin-bottom: 10px;
                            }
                            p {
                                width: 155px;
                                font-size: 14px;
                                color: #666666;
                                text-overflow: -o-ellipsis-lastline;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                line-clamp: 2;
                                -webkit-box-orient: vertical;
                            }
                        }
                    }
                    .download-li {
                        width: 282px;
                        box-sizing: border-box;
                        line-height: 17px;
                        margin: 0 auto 18px;
                        font-size: 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        padding-left: 10px;
                        display: inline-block;
                        position: relative;
                        img {
                            width: 19px;
                            height: 19px;
                            margin-right: 4px;
                            vertical-align: middle;
                        }
                    }
                    .download-li::before {
                        content: '';
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background: #ffac13;
                        position: absolute;
                        left: 0;
                        top: 8px;
                    }
                }
            }
        }
    }
}
