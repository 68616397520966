
.swiper-subject-component {
    // height: 240px;
    .box {
        // margin-top: 300px;
        width: 100%;
        height: 240px;
        position: relative;
    }

    .list {
        width: 1200px;
        height: 240px;
        overflow: hidden;
        position: absolute;
        left: 50%;
        margin-left: -600px;
        // background: red;
        li a {
            img {
                background: rgba(0, 0, 0, 0.2);
            }
        }
    }

    .btn {
        position: absolute;
        left: 287px;
        top: 50%;
        width: 26px;
        height: 26px;
        line-height: 22px;
        font-size: 22px;
        color: white;
        text-decoration: none;
        text-align: center;
        background: #bd1a2d;
        opacity: 0.8;
        cursor: pointer;
        z-index: 100;
    }

    .next {
        left: auto;
        right: 271px;
    }

    li {
        position: absolute;
        top: 0;
        left: 0;
        list-style: none;
        opacity: 0;
        transition: all 0.3s ease-out;
        a {
            display: block;
            width: 662px;
            height: 240px;
            p {
                display: none;
            }
        }
    }

    img {
        width: 662px;
        height: 240px;
        border: none;
        float: left;
    }

    .p1 {
        transform: translate3d(-275px, 0, 0) scale(1);
        // transform: translate3d(-224px, 0, 0) scale(1);
    }

    .p2 {
        transform: translate3d(0px, 0, 0) scale(1);
        transform-origin: 0 50%;
        opacity: 0.8;
        z-index: 2;
    }

    .p3 {
        transform: translate3d(275px, 0, 0) scale(1);
        // transform: translate3d(224px, 0, 0) scale(1);
        z-index: 3;
        opacity: 1;
        border-left: 2px solid #fff;
        border-right: 2px solid #fff;

        a {
            position: relative;

            p {
                display: block;
                width: 662px;
                position: absolute;
                bottom: 0;
                left: 0;
                line-height: 46px;
                color: #fff;
                background: rgba(0, 0, 0, 0.5);
                font-size: 22px;

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
            }
        }
    }

    .p4 {
        transform: translate3d(550px, 0, 0) scale(1);
        // transform: translate3d(449px, 0, 0) scale(1);
        transform-origin: 100% 50%;
        opacity: 0.8;
        z-index: 2;
    }

    .p5 {
        transform: translate3d(825px, 0, 0) scale(1);
        // transform: translate3d(672px, 0, 0) scale(1);
    }

    .p6 {
        transform: translate3d(1100px, 0, 0) scale(1);
        // transform: translate3d(896px, 0, 0) scale(1);
    }

    .p7 {
        transform: translate3d(1375px, 0, 0) scale(1);
        // transform: translate3d(1120px, 0, 0) scale(1);
    }

    .buttons {
        position: absolute;
        width: 1200px;
        height: 30px;
        bottom: 0;
        left: 50%;
        margin-left: -600px;
        text-align: center;
        padding-top: 10px;
    }

    .buttons a {
        display: inline-block;
        width: 35px;
        height: 5px;
        padding-top: 4px;
        cursor: pointer;
    }

    span {
        display: block;
        width: 35px;
        height: 1px;
        background: red;
    }

    .blue {
        background: blue;
    }
}
